export default [
  'beforeCalendarHeaderDay',
  'calendarFooterDay',
  'beforeCalendarHeaderMonth',
  'calendarFooterMonth',
  'beforeCalendarHeaderYear',
  'calendarFooterYear',
  'nextIntervalBtn',
  'prevIntervalBtn',
]
